<script>
  let show = true
  export let cb
  let fade_class = 'message__show'
  const MESSAGE_DISPLAY_TIME = 5000
  const ANIMATION_DURATION = 500
  setTimeout(() => {
    fade_class = 'message__hide'
  }, MESSAGE_DISPLAY_TIME)

  setTimeout(() => {
    fade_class = 'message_display_none'
    cb()
  }, MESSAGE_DISPLAY_TIME + ANIMATION_DURATION)
</script>

<style lang="scss">@keyframes flash-slide-top {
  0% {
    opacity: 0;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes flash-slide-up {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(-1rem); } }

.message_display_none {
  display: none; }

.message__show {
  animation: flash-slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.message__hide {
  animation: flash-slide-up 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.wrapper__success {
  background-color: var(--primary_success_colour);
  padding: 1rem;
  border-radius: 0.5rem; }

.tick {
  min-width: 0.6rem;
  min-height: 1rem;
  transform: rotate(45deg);
  border-width: 0 4px 4px 0;
  border-color: var(--primary_white);
  border-style: solid;
  display: inline-block; }

.tick_circle {
  width: 2rem;
  height: 2rem;
  background-color: var(--primary_green_dark);
  border-radius: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center; }

/*# sourceMappingURL=FlashMessage.svelte.css.map */</style>

{#if show}
  <div class={`wrapper__success ${fade_class}`}>
    <div class="tick_circle">
      <div class="tick" />
    </div>
    <slot />
  </div>
{/if}
