<script>
  import { onMount, onDestroy } from 'svelte'
  import { link } from 'svelte-navigator'
  import { functions } from '../../firebase'

  import { firestore } from '../../firebase'
  import { userStore } from '../../store/user'
  import { loading } from '../../store/loading'
  import FlashMessage from '../App/FlashMessage.svelte'

  export let providerId = null
  const disableProvider = functions.httpsCallable('disableProvider')

  let initalState = {
    email: '',
    emailError: '',
    providerName: '',
    providerNameError: '',
    solarplatformAPIKey: '',
    dataRetentionMonths: 12,
    solarplatformAPIKeyError: '',
    solarplatformAPISharedSecret: '',
    solarplatformAPISharedSecretError: '',
    dataRetentionMonthsError: '',
    inactive: false,
    isSubmitting: false,
    displayFlashMessage: false
  }

  let state = Object.assign({}, initalState)
  let unsubUsersCollection
  let unsubUsersStore
  let accountStatus = null

  const ACTIVE = 'Active',
    INACTIVE = 'Inactive'

  const providerLoaded = (querySnapshot) => {
    if (querySnapshot.exists) {
      const providerData = querySnapshot.data()
      state.providerName = providerData.providerName
      state.email = providerData.email
      state.solarplatformAPIKey = providerData.solarplatformAPIKey
      state.solarplatformAPISharedSecret = providerData.solarplatformAPISharedSecret
      state.inactive = providerData.inactive ? true : false

      accountStatus = state.inactive ? INACTIVE : ACTIVE
    }
  }

  const isEmailValid = () => state.email !== ''

  const isAPIKeyValid = () => state.solarplatformAPIKey !== ''

  const isSharedSecretValid = () => state.solarplatformAPISharedSecret !== ''

  const isProviderNameValid = () => state.providerName !== ''

  const isRetentionPeriodValid = () => state.dataRetentionMonths && state.dataRetentionMonths <= 24

  const canSubmit = () => isEmailValid() && isAPIKeyValid() && isSharedSecretValid() && isProviderNameValid()

  const handleRemove = () => null

  const handleSubmit = async () => {
    clearErrors()
    if (!isEmailValid()) state.emailError = 'Invalid Provider Email'
    if (!isProviderNameValid()) state.providerNameError = 'Invalid Provider Name'
    if (!isAPIKeyValid()) state.solarplatformAPIKeyError = 'Invalid API Key'
    if (!isSharedSecretValid()) state.solarplatformAPISharedSecretError = 'Invalid Shared Secret'
    if (!isRetentionPeriodValid())
      state.dataRetentionMonthsError = 'Invalid data retention period (must be present and less than 24 months)'
    if (canSubmit()) {
      loading.set(true)

      await firestore.collection('users').doc(providerId).update({
        email: state.email,
        providerName: state.providerName,
        solarplatformAPIKey: state.solarplatformAPIKey,
        solarplatformAPISharedSecret: state.solarplatformAPISharedSecret,
        inactive: state.inactive
      })
      disableProvider({ providerId, disabled: state.inactive })
      state.displayFlashMessage = true
      loading.set(false)
    } else {
      alert('Form is not valid')
    }
  }

  const handleStatus = (status) => () => {
    accountStatus = status

    if (accountStatus === INACTIVE) {
      state.inactive = true
    } else {
      state.inactive = false
    }
  }

  const clearErrors = () => {
    state.emailError = ''
    state.solarplatformAPIKeyError = ''
    state.solarplatformAPISharedSecretError = ''
    state.providerNameError = ''
    state.dataRetentionMonthsError = ''
  }

  const resetFlashMessageCB = () => {
    state.displayFlashMessage = false
  }

  unsubUsersStore = userStore.subscribe((user) => {
    if (!user) unsubUsersCollection()
  })

  onMount(async () => {
    unsubUsersCollection = await firestore.collection('users').doc(providerId).onSnapshot(providerLoaded)
  })

  onDestroy(unsubUsersStore)
</script>

<style lang="scss">input {
  margin: 0 0 0px 0; }

button {
  margin: 20px 10px 0 0; }

.danger {
  background-color: #ce620f; }

.danger:disabled {
  background-color: #c9bdb5;
  cursor: not-allowed; }

.danger:not(:disabled):active {
  background-color: #ce1c0f; }

.danger:focus {
  -webkit-transition: border-bottom 0.2s ease-in;
  -moz-transition: border-bottom 0.2s ease-in;
  -o-transition: border-bottom 0.2s ease-in;
  transition: border-bottom 0.2s ease-in;
  border-bottom: 2px solid #ce1c0f; }

.danger:not(:disabled):hover {
  background-color: #ce1c0f;
  -webkit-transition: background-color 0.2s ease-in;
  -moz-transition: background-color 0.2s ease-in;
  -o-transition: background-color 0.2s ease-in;
  transition: background-color 0.2s ease-in;
  border-bottom: 2px solid #000; }

.error {
  font-size: 10px;
  color: #ff0000; }

.select-box {
  border: 1px solid var(--primary_very_light_gray);
  border-radius: 0.2rem;
  overflow: hidden; }

.select-box > div {
  padding: 0.5rem;
  cursor: pointer; }

.select-box > [data-select='true'] {
  background-color: var(--primary_blue);
  color: var(--primary_white); }

/*# sourceMappingURL=EditProvider.svelte.css.map */</style>

<div class="toolbar__header layout__flex_column layout__flex_container_bottom svelte__wrapper" />

<div class="box centered">
  <h1>Edit Provider</h1>

  {#if state.displayFlashMessage}
    <FlashMessage cb={resetFlashMessageCB}>Provider details have been updated successfully!</FlashMessage>
  {/if}

  <label for="status">Status</label>
  <div class="layout__inline_flex select-box">
    <div data-select={accountStatus === ACTIVE} on:click={handleStatus(ACTIVE)}>{ACTIVE}</div>
    <div data-select={accountStatus === INACTIVE} on:click={handleStatus(INACTIVE)}>{INACTIVE}</div>
  </div>

  <label for="providerName">Provider Name</label>
  <input type="text" name="providerName" bind:value={state.providerName} />
  <span class="error">{state.providerNameError}</span>

  <label for="email">Provider Email</label>
  <input type="text" name="email" bind:value={state.email} />
  <span class="error">{state.emailError}</span>

  <label for="solarplatformAPIKey">Solar Platform API Key</label>
  <input type="text" name="solarplatformAPIKey" bind:value={state.solarplatformAPIKey} />
  <span class="error">{state.solarplatformAPIKeyError}</span>

  <label for="solarplatformAPISharedSecret">Solar Platform Shared Secret</label>
  <input type="text" name="solarplatformAPISharedSecret" bind:value={state.solarplatformAPISharedSecret} />
  <span class="error">{state.solarplatformAPISharedSecretError}</span>

  <label for="dataRetentionMonths">Data Retention Period (months)</label>
  <input type="number" name="dataRetentionMonths" bind:value={state.dataRetentionMonths} />
  <span class="error">{state.dataRetentionMonthsError}</span>

  <br />
  <br />

  <a href={`/provider/${providerId}/customer`} class="customers-link" use:link>Show Customers</a>
  <div>
    <button on:click={handleRemove} class="danger" disabled>Remove Provider</button>
    <button on:click={handleSubmit} disabled={state.isSubmitting}>Update Provider</button>
  </div>
</div>
