<style>
  .container {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 16rem;
  }
</style>

<div class="container">
  <div class="icon-logo icon--medium" />

  <div class="layout__inline typography__white typography__header_4 typography__medium">Powersensor</div>
  <div class="layout__inline typography__header_5">PROVIDER PROTAL</div>

</div>
